<template>
    <div>
        <div class="m-wrapper04" id="index-default">
            <div class="m-setting02">
                <img src="../../assets/images/avatar-2.png" class="doctor-avatar04">
                <div class="doctor-info-box05">
                    <div class="doctor-name03">郑铭</div>
                    <div class="doctor-type03">主任医师</div>
                </div>
            </div>
            <ul class="appoint-info-con01">
                <li class="setting-con01">
                    <span class="setting-lab01">就诊医院</span>
                    <p class="setting-ipt02">福建省立医院</p>
                </li>
                <li class="setting-con01">
                    <span class="setting-lab01">就诊科室</span>
                    <p class="setting-ipt02">内分泌</p>
                </li>
                <li class="setting-con01">
                    <span class="setting-lab01">门诊类型</span>
                    <p class="setting-ipt02">特诊门诊</p>
                </li>
                <li class="setting-con01">
                    <span class="setting-lab01">诊查费</span>
                    <p class="setting-ipt02">50.0元</p>
                </li>
            </ul>
            <ul class="m-setting01">
                <li class="setting-con02" @click="chooseNumber">
                    <div class="setting-box01">
                        <span class="setting-lab02">预约时段</span>
                        <input type="text" readonly="readonly" id="jz-number" :value="`2021-04-12(周一上午)${choosedNumber}`" class="setting-ipt03 red setting-choosenum">
                    </div>
                </li>
                <li class="setting-con02">
                    <div class="setting-box01">
                        <span class="setting-lab02">就诊人</span>
                        <input type="text" readonly="readonly" id="patient" value="张三" class="setting-ipt03 setting-ipt03-none">
                    </div>
                </li>
                <li class="setting-con02" @click="showAddCardModal">
                    <div class="setting-box01">
                        <span class="setting-lab02">就诊卡号</span>
                        <input type="text" readonly="readonly" id="jz-card" :value="choosedCard" class="setting-ipt03">
                    </div>
                    <p class="tip02">
                        请填写正确的就诊卡号，若无就诊卡可在线申请代办。
                    </p>
                </li>
                <li class="setting-con02">
                    <div class="setting-box01">
                        <span class="setting-lab02">证件号码</span>
                        <input type="text" value="350*********0025" class="setting-ipt04">
                    </div>
                    <p class="tip02">
                        医院实行实名制预约，预约时必须填写本人正确的身份证号，否则将影响取号就诊。
                    </p>
                </li>
                <li class="setting-con02">
                    <div class="setting-box01">
                        <span class="setting-lab02">证件类型</span>
                        <input @click="toggleCardType" type="text" :value="cartTypeObj[recordObj['cardType']]" class="setting-ipt04">
                    </div>
                    <p class="tip02">
                        证件类型必须选择
                    </p>
                </li>
                <li class="setting-con02">
                    <div class="setting-box01">
                        <span class="setting-lab02">性别</span>
                        <input @click="toggleSex" type="text" readonly :value="sexObj[recordObj.sex]" class="setting-ipt04">
                    </div>
                </li>
                <li class="setting-con02" @click="chooseDisease">
                    <div class="setting-box01">
                        <span class="setting-lab02">选择疾病</span>
                        <input type="text" readonly="readonly" id="disease" placeholder="必填" :value="choosedDisease" class="setting-ipt03">
                    </div>
                </li>
                <li class="setting-con02">
                    <div class="setting-box01">
                        <span class="setting-lab02">支付方式</span>
                        <input type="text" readonly="readonly" value="到院支付" class="setting-ipt03 setting-payType">
                    </div>
                </li>
            </ul>
            <a @click="record" class="appoint-btn01">立即预约</a>
        </div>
        <!-- 选择性别 -->
        <van-popup position="bottom" v-model="showSex" class="card-type-container">
            <ul class="card-type">
                <li @click="chooseSex(item)" v-for="item in sexList" :key="item.code">{{item.val}}</li>
            </ul>
        </van-popup>
        <!-- 选择证件类型 -->
        <van-popup position="bottom" v-model="showCardType" class="card-type-container">
            <ul class="card-type">
                <li @click="chooseCardType(index)" v-for="(item, index) in cardTypeList" :key="item.code">{{item.val}}</li>
            </ul>
        </van-popup>
        <!-- 选择就诊卡 -->
        <div class="recordcard-container">
            <record-card :showCardModal.sync="showCardModal" @getChooseCard="getChooseCard"></record-card>
        </div>
        <!-- 选择号源 -->
        <choose-number :showChooseNum.sync="showChooseNum" @getChooseNumber="getChooseNumber"></choose-number>
        <!-- 选择疾病 -->
        <choose-disease :showChooseDisease.sync="showChooseDisease" @getChoosedDisease="getChoosedDisease"></choose-disease>
    </div>
</template>
<script>
import RecordCard from './recordCard'
import ChooseNumber from './chooseNumber'
import ChooseDisease from './chooseDisease'
import {mapState} from 'vuex'
import {cardTypeList, cartTypeObj, sexObj, sexList} from '@/constant/constant'
import store from '@/store'
export default {
    store,
    data() {
        return {
            recordCardList: [{
                cardValue: 'A033123456'
            }],
            showCardModal: false,
            showChooseNum: false,
            showChooseDisease: false,
            choosedCard: '',
            choosedNumber: '',
            choosedDisease: '',
            showCardType: false,
            showSex: false,
            cardTypeList: [],
            cartTypeObj: {},
            sexObj,
            sexList
        }
    },
    components: {
        RecordCard,
        ChooseNumber,
        ChooseDisease
    },
    mounted() {
        this.cardTypeList = cardTypeList;
        this.cartTypeObj = cartTypeObj;
    },
    computed: {
        // recordObj控制预约信息界面
        ...mapState({
            recordObj: state => state.record.recordObj
        })
    },
    methods: {
        toggleSex() {
            this.showSex = true
        },
        chooseSex(sexItem) {
            // 选择性别
            this.$store.commit('updateRecordObj', {
                sex: sexItem.code
            })
            this.showSex = false
        },
        chooseCardType(index) {
            // 更新证件类型
            this.$store.commit('updateRecordObj', {
                cardType: index + 1
            })
            this.showCardType = false
        },
        toggleCardType() {
            // 选择证件类型
            this.showCardType = true
        },
        showAddCardModal() {
            this.showCardModal = true
        },
        getChooseCard(card) {
            this.choosedCard = card
        },
        chooseNumber() {
            this.showChooseNum = true;
        },
        getChooseNumber(number) {
            this.choosedNumber = number;
        },
        chooseDisease() {
            this.showChooseDisease = true;
        },
        getChoosedDisease(text) {
            this.choosedDisease = text
        },
        record() {
            // 立即预约 -- 如果还没有实名认证优先实名认证一下
            this.$store.dispatch('setRealNameInfo')
            this.$router.push('/appointResult/1') //1预约取消 2预约成功
        }
    }
}
</script>
<style lang="scss" scoped>
$mainColor: #00C2C3;
.m-wrapper04 {
    text-align: left;

    .setting-ipt03-none {
        background: none;
    }

    .setting-ipt03 {
        padding-right: 20px;
        box-sizing: border-box;
    }

    .appoint-btn01 {
        background: $mainColor;
    }

    .setting-ipt02, .setting-ipt03, .setting-ipt04 {
        text-align: right;
    }

    .setting-choosenum {
        padding-right: 20px;
        box-sizing: border-box;
    }

    .setting-payType {
        background: none;
        padding-right: 0;
    }

    
}

.card-type-container {
    width: 100%;
    max-height: 200px;
    .card-type {
        width: 100%;

        li {
            height: 50px;
            line-height: 50px;
            border-bottom: 1px solid #ddd;
            font-size: 16px;
        }
    }
}

.setting-lab01, .setting-lab02 {
    color: #999;
}

</style>