<template>
    <!-- 就诊卡弹窗 -->
    <div v-if="showCardModal" id="select-pop03" class="weui-popup__container popup-bottom">
        <div @click="closeModal" class="weui-popup__overlay"></div>
        <div class="weui-popup__modal card-modal">
            <ul class="m-select02">
                <li @click="chooseCard(card)" v-for="card in cardList" :key="card.value">{{card.value}}</li>
            </ul>
            <a @click="addCard" class="link-btn02">添加就诊卡</a>
        </div>
    </div>
</template>
<script>
export default {
    props: ['showCardModal'],
    data() {
        return {
            cardList: [{
                id: 1,
                value: '就诊卡：A03668112'
            }, {
                id: 2,
                value: '医保卡：A03668113'
            }]
        }
    },
    methods: {
        addCard() {
            // 添加就诊卡
            this.$router.push('/addCard/1')
        },
        closeModal() {
            this.$emit('update:showCardModal', false)
        },
        chooseCard(card) {
            // 选择就诊卡
            this.$emit('getChooseCard', card.value)
            this.closeModal();
        }
    }
}
</script>
<style lang="scss" scoped>
.weui-popup__overlay {
    opacity: 1;
    background: rgba(0,0,0,.4);
    transition: opacity .3s;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
}

.card-modal {
    z-index: 100;
    position: fixed;
    width: 100%;
    bottom: 0;
}
</style>