<template>
    <!-- 选择号源弹窗 -->
    <div v-if="showChooseNum" id="select-pop01" class="weui-popup__container popup-bottom">
        <div class="weui-popup__overlay" @click="closeModal"></div>
        <div class="weui-popup__modal popup-modal">
            <div class="appoint-time03">
                <span>2021-04-05</span>
                <span>星期四</span>
                <span>上午</span>
            </div>
            <div class="appoint-doctor-name01">
                候建明（内分泌）
            </div>
            <ul class="m-select01">
                <li v-for="(item, index) in numberList" class="select-con01" :key="item.number" @click="chooseItem(index)">
                    <div class="select-box01"><span class="appoint-num01">第{{item.number}}号</span><span>{{item.time}}</span></div>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    props: ['showChooseNum'],
    data() {
        return {
            numberList: [{
                number: 1,
                time: '10:21'
            }, {
                number: 2,
                time: '10:21'
            },{
                number: 3,
                time: '10:21'
            },{
                number: 4,
                time: '10:21'
            },{
                number: 5,
                time: '10:21'
            },{
                number: 6,
                time: '10:21'
            },{
                number: 7,
                time: '10:21'
            },{
                number: 8,
                time: '10:21'
            },{
                number: 9,
                time: '10:21'
            },{
                number: 10,
                time: '10:21'
            },{
                number: 11,
                time: '10:21'
            },{
                number: 12,
                time: '10:21'
            },]
        }
    },
    methods: {
        closeModal() {
            this.$emit('update:showChooseNum', false)
        },
        chooseItem(index) {
            let val = `第${this.numberList[index]['number']}号 ${this.numberList[index]['time']}`
            this.$emit('getChooseNumber', val)
            this.closeModal()
        }
    }
}
</script>
<style lang="scss" scoped>
.weui-popup__overlay {
    opacity: 1;
    background: rgba(0,0,0,.4);
    transition: opacity .3s;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
}

.popup-modal {
    z-index: 100;
    position: fixed;
    width: 100%;
    bottom: 0;
}
</style>