<template>
    <!-- 选择疾病弹窗 -->
    <div v-if="showChooseDisease" class="m-wrapper08 disease-container" id="index-search">
        <div class="m-search01">
            <a href="javascript:;" class="return-icon01" @click="closePop"></a>
            <input type="text" placeholder="请输入疾病" class="search02">
            <a href="javascript:;" class="search-btn01">搜索</a>
        </div>
        <ul class="deasease-list">
            <li v-for="item in diseaseList" :key="item.val" @click="chooseDisease(item)">{{item.val}}</li>
        </ul>
    </div>
</template>
<script>
export default {
    props: ['showChooseDisease'],
    data() {
        return {
            diseaseList: [{
                val: '未确诊'
            }, {
                val: '甲亢'
            }]
        }
    },
    methods: {
        closePop() {
            this.$emit('update:showChooseDisease', false)
        },
        chooseDisease(item) {
            this.$emit('getChoosedDisease', item.val)
            this.closePop()
        }
    }
}
</script>
<style lang="scss" scoped>
.disease-container {
    position: fixed;
    width: 100%;
    top: 0;

    .deasease-list {
        text-align: left;
    }
}
</style>